<template>
  <ValidationProvider v-if="withValidation" v-slot="{ errors }" :mode="customMode" :rules="validationRules">
    <component
      :is="component"
      v-bind="{ ...$props, ...$attrs }"
      :prop-path="propPath"
      :value="getPropertyValue({ propPath })"
      :payload="payloadData"
      :errors="errors"
      :label="transformLabel"
      :disabled="disabled"
      :required="required"
      @input="setPropertyValue({ propPath, value: $event })"
    />
  </ValidationProvider>
  <div v-else>
    <component
      :is="component"
      v-bind="{ ...$props, ...$attrs }"
      :prop-path="propPath"
      :value="getPropertyValue({ propPath })"
      :payload="payloadData"
      :label="transformLabel"
      :disabled="disabled"
      :required="required"
      @input="setPropertyValue({ propPath, value: $event })"
    />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { isObject, isEqual } from '@/utils/common';
import { getPropertyByPath } from '@/utils/schema';

import * as schemaItemTypes from '@/schemas/schemaItemTypes';

import { rules } from '@/schemas/validationRules';
import DatePickerWithPanel from '@/components/DatePickerWithPanel.vue';
import FormFieldString from './FormFieldString.vue';
import FormFieldProject from './FormFieldProject.vue';
import FormFieldBuilding from './FormFieldBuilding.vue';
import FormFieldEmployee from './FormFieldEmployee.vue';
import FormFieldBoolean from './FormFieldBoolean.vue';
import FormFieldEnum from './FormFieldEnum.vue';
import FormFieldNumber from './FormFieldNumber.vue';
import FormFieldList from './FormFieldList.vue';
import FormFieldScriptList from './FormFieldScriptList.vue';
import FormFieldImageList from './FormFieldImageList.vue';
import FormFieldFileList from './FormFieldFileList.vue';
import FormFieldDate from './FormFieldDate.vue';
import FormFieldClient from './FormFieldClient.vue';
import FormFieldRole from './FormFieldRole.vue';
import FormFieldUnit from './FormFieldUnit.vue';
import FormFieldRoom from './FormFieldRoom.vue';
import FormFieldContractor from './FormFieldContractor.vue';
import FormFieldPercent from './FormFieldPercent.vue';
import FormTotalAmount from './FormTotalAmount.vue';
import FormFieldSquare from './FormFieldSquare.vue';
import FormTitle from './FormTitle.vue';
import FormFieldCategory from './FormFieldCategory.vue';
import FormFieldManyProjects from './FormFieldManyProjects.vue';
import FormFieldManyUnits from './FormFieldManyUnits.vue';
import FormFieldManyBuildings from './FormFieldManyBuildings.vue';
import FormFieldManyClients from './FormFieldManyClients.vue';
import FormFieldManyPerformers from './FormFieldManyPerformers.vue';
import FormFieldManyPerformersForProject from './FormFieldManyPerformersForProject.vue';
import FormFieldManyPerformersForTask from './FormFieldManyPerformersForTask.vue';
import FormFieldManyServiceTypes from './FormFieldManyServiceTypes.vue';
import FormFieldManyContractorsTypes from './FormFieldManyContractorsTypes.vue';
import FormFieldTime from './FormFieldTime.vue';
import FormFieldCredentials from './FormFieldCredentials.vue';
import FormFieldRolesList from './FormFieldRolesList.vue';
import FormFieldTeamList from './FormFieldTeamList.vue';
import FormFieldAgreementStatusSelect from './FormFieldAgreementStatusSelect.vue';
import FormFieldClientType from './FormFieldClientType.vue';

// eslint-disable-next-line import/no-unresolved
import FormFieldCountry from './FormFieldCountry.vue';
import FormFieldSearchBar from './FormFieldSearchBar.vue';
import FormUnitContracts from './FormUnitContracts.vue';
import FormUnitEnum from './FormUnitEnum.vue';
import FormFieldPaymentType from './FormFieldPaymentType.vue';

import FormTitledSection from './FormTitledSection.vue';
import FormRow from './FormRow.vue';
import FormFlexRow from './FormFlexRow.vue';

const UNKNOWN_FIELD = {
  render: h => h('p', 'unknown field'),
};

const FORM_FIELD_MAP = {
  [schemaItemTypes.STRING]: FormFieldString,
  [schemaItemTypes.PROJECT]: FormFieldProject,
  [schemaItemTypes.BUILDING]: FormFieldBuilding,
  [schemaItemTypes.EMPLOYEES]: FormFieldEmployee,
  [schemaItemTypes.ENUM]: FormFieldEnum,
  [schemaItemTypes.UNIT_ENUM]: FormUnitEnum,
  [schemaItemTypes.NUMBER]: FormFieldNumber,
  [schemaItemTypes.BOOLEAN]: FormFieldBoolean,
  [schemaItemTypes.LIST]: FormFieldList,
  [schemaItemTypes.SCRIPT_LIST]: FormFieldScriptList,
  [schemaItemTypes.IMAGE_LIST]: FormFieldImageList,
  [schemaItemTypes.FILE_LIST]: FormFieldFileList,
  [schemaItemTypes.LINK]: FormFieldString,
  [schemaItemTypes.DATE]: FormFieldDate,
  [schemaItemTypes.CLIENT]: FormFieldClient,
  [schemaItemTypes.ROLE]: FormFieldRole,
  [schemaItemTypes.UNIT]: FormFieldUnit,
  [schemaItemTypes.ROOM]: FormFieldRoom,
  [schemaItemTypes.CONTRACTOR]: FormFieldContractor,
  [schemaItemTypes.PERCENT]: FormFieldPercent,
  [schemaItemTypes.TOTAL_AMOUNT]: FormTotalAmount,
  [schemaItemTypes.SQUARE]: FormFieldSquare,
  [schemaItemTypes.COUNTRY]: FormFieldCountry,
  [schemaItemTypes.CATEGORY]: FormFieldCategory,
  [schemaItemTypes.MANY_PROJECTS]: FormFieldManyProjects,
  [schemaItemTypes.MANY_BUILDINGS]: FormFieldManyBuildings,
  [schemaItemTypes.MANY_UNITS]: FormFieldManyUnits,
  [schemaItemTypes.MANY_CLIENTS]: FormFieldManyClients,
  [schemaItemTypes.MANY_PERFORMERS]: FormFieldManyPerformers,
  [schemaItemTypes.MANY_PERFORMERS_FOR_TASK]: FormFieldManyPerformersForTask,
  [schemaItemTypes.MANY_PERFORMERS_FOR_PROJECT]: FormFieldManyPerformersForProject,
  [schemaItemTypes.MANY_SERVICE_TYPES]: FormFieldManyServiceTypes,
  [schemaItemTypes.MANY_CONTRACTORS_TYPES]: FormFieldManyContractorsTypes,
  [schemaItemTypes.TIME]: FormFieldTime,
  [schemaItemTypes.CREDENTIALS_VARIANT]: FormFieldCredentials,
  [schemaItemTypes.DATE_WITH_PANEL]: DatePickerWithPanel,
  [schemaItemTypes.SEARCH_BAR]: FormFieldSearchBar,
  [schemaItemTypes.UNIT_CONTRACTS]: FormUnitContracts,
  [schemaItemTypes.MANY_PAYMENT_TYPE]: FormFieldPaymentType,
  [schemaItemTypes.ROLES_LIST]: FormFieldRolesList,
  [schemaItemTypes.TEAM_LIST]: FormFieldTeamList,
  [schemaItemTypes.AGREEMENT_STATUS_SELECT]: FormFieldAgreementStatusSelect,
  [schemaItemTypes.CLIENT_TYPE]: FormFieldClientType,

  // не влияют на модель данных
  [schemaItemTypes.TITLE]: FormTitle,
  [schemaItemTypes.TITLED_SECTION]: FormTitledSection,
  [schemaItemTypes.ROW]: FormRow,
  [schemaItemTypes.FLEX_ROW]: FormFlexRow,
};

const VEE_VALIDATE_RULE_SEPARATOR = '|';

export default {
  name: 'SchemaFormItem',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  inject: {
    getPropertyValue: 'getPropertyValue',
    setPropertyValue: 'setPropertyValue',
    getPayloadForItem: 'getPayloadForItem',
    globalConfig: 'globalConfig',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    payload: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeLabelSuffix: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component() {
      return FORM_FIELD_MAP[this.type] || UNKNOWN_FIELD;
    },

    withValidation() {
      return [
        schemaItemTypes.LIST,
        schemaItemTypes.SCRIPT_LIST,
        schemaItemTypes.TITLED_SECTION,
        schemaItemTypes.ROW,
        schemaItemTypes.TOTAL_AMOUNT,
        schemaItemTypes.TITLE,
        schemaItemTypes.CREDENTIALS_VARIANT,
        schemaItemTypes.UNIT_CONTRACTS,
      ].every(type => this.type !== type);
    },

    payloadData() {
      return this.getPayloadForItem({ payload: this.payload }, this.propPath);
    },

    // specific rules for vee-validate
    validationRules() {
      if (this.payloadData.disabled || this.disabled) {
        return '';
      }

      return this.rules.join(VEE_VALIDATE_RULE_SEPARATOR);
    },

    required() {
      return this.rules.includes(rules.REQUIRED) || this.rules.includes(rules.MANY_REQUIRED);
    },

    postfixLabel() {
      return this.rules.includes(rules.REQUIRED) || this.rules.includes(rules.MANY_REQUIRED)
        ? '*'
        : this.$t('label.optional');
    },

    translateLabel() {
      return this.$t(this.getLabelFromPayload());
    },

    transformLabel() {
      if (this.withValidation && !this.globalConfig.removeLabelSuffix && !this.removeLabelSuffix) {
        return `${this.translateLabel} ${this.postfixLabel}`;
      }

      return this.translateLabel;
    },
  },

  methods: {
    getLabelFromPayload() {
      if (isObject(this.label)) {
        const value = getPropertyByPath(this.payloadData, this.label.from);

        return (
          this.label.options.find(option => {
            return isEqual(option.value, value);
          })?.key ||
          this.label?.default ||
          ''
        );
      }

      return this.label;
    },

    customMode() {
      return {
        on: ['change', 'blur', 'validate'],
      };
    },
  },
};
</script>
